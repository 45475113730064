<template>
  <fsc-page-header class="border-0" :remove-spacing="true">
    <span class="pr-4 border-right py-1">
      {{ $t("sidebar.automatic_payments") }}
    </span>
    <span class="pl-4 py-1">
      <slot>
        {{ $t("automatic_payment.classic_pay") }}
      </slot>
    </span>
  </fsc-page-header>
</template>

<script>
import FscPageHeader from "@/components/FscPageHeader.vue";

export default {
  name: "PaymentHeader",
  components: { FscPageHeader },
};
</script>
