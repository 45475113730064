




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BooleanStatus extends Vue {
  public name = "BooleanStatus";

  @Prop({ type: Boolean, default: () => false })
  public value!: boolean;
}
