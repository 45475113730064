












import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import { v4 as uuidv4 } from "uuid";

@Component
export default class FilePlusButton extends mixins(ButtonMixin) {
  public name = "FilePlusButton";

  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop()
  public tooltipLabel: any;
}
