

































































































































































































import { Component } from "vue-property-decorator";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import Table from "@/components/Table.vue";
import Actions from "@/components/Actions.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";
import FscCard from "@/components/Card/FscCard.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import StudentCol from "@/views/AutomaticPaymentsOverview/StudentCol.vue";
import { FLASH_PAY } from "@/constants/PaymentWorkflow";
import TextDecimal from "@/components/TextDecimal.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import PaymentHeader from "@/views/AutomaticPaymentsOverview/PaymentHeader.vue";
import BooleanStatus from "@/components/BooleanStatus.vue";
import { mixins } from "vue-class-component";
import AutomaticPaymentMixin from "@/mixins/AutomaticPaymentMixin";
import FileCheckButton from "@/components/Button/FileCheckButton.vue";
import MagicStickButton from "@/components/Button/MagicStickButton.vue";
import GenerateStudentInvoiceModal from "@/views/AutomaticPaymentsOverview/GenerateStudentInvoiceModal.vue";
import FilePlusButton from "@/components/Button/FilePlusButton.vue";
import _ from "lodash";
import PaymentWorkflowFilterModal from "@/components/Modal/PaymentWorkflowFilterModal.vue";
import DraftInvoiceButton from "@/components/Button/DraftInvoiceButton.vue";

const FlashPayModule = namespace("flash-pay");
const FlashPayFilterModule = namespace("flash-pay/localFilter");
const AppModule = namespace("app");
const SaldoModule = namespace("saldo");

@Component({
  components: {
    PaymentWorkflowFilterModal,
    FilePlusButton,
    GenerateStudentInvoiceModal,
    MagicStickButton,
    FileCheckButton,
    BooleanStatus,
    PaymentHeader,
    VerticalDivider,
    FscSimpleCard,
    TextDecimal,
    StudentCol,
    FilterAndSearch,
    FscCard,
    PrintButton,
    FileCsvButton,
    Actions,
    Table,
    Hint,
    DraftInvoiceButton,
  },
})
export default class FlashPay extends mixins(AutomaticPaymentMixin) {
  public name = "FlashPay";

  protected filtered = "";

  protected filterModal = "flash-pay-filter-modal";

  protected modalId = "flash-pay-modal-id";

  protected studentEducationFields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "student",
      label: "",
    },
    {
      key: "status",
      label: this.$t("general.status"),
    },
    {
      key: "licenseClass",
      label: this.$t("sidebar.training"),
    },
    {
      key: "payments",
      label: this.$t("automatic_payment.payments_made"),
    },
    {
      key: "actualBalance",
      label: this.$t("automatic_payment.actual_balance"),
    },
    {
      key: "previewBalance",
      label: this.$t("automatic_payment.preview_balance"),
    },
    {
      key: "practicalExamDate",
      label: this.$t("automatic_payment.practical_exam_date"),
    },
    {
      key: "automaticPaymentEnabled",
      label: this.$t("automatic_payment.automatic_payment"),
    },
    {
      key: "lastPartialInvoiceDate",
      label: this.$t("automatic_payment.date_of_last_partial_invoice"),
    },
    {
      key: "finishedEducation",
      label: this.$t("automatic_payment.education_finished"),
    },
    {
      key: "finalInvoiceDate",
      label: this.$t("automatic_payment.date_of_final_invoice"),
    },
  ];

  @FlashPayFilterModule.State("filter")
  public flashPayLocalFilter: any;

  @FlashPayFilterModule.Mutation("SET_LOCAL_FILTER")
  public setFlashPayLocalFilter!: (payload: Record<any, any>) => void;

  @FlashPayModule.Action("findAllStudentList")
  public paymentWorkflowsStudentListFindAll: any;

  @FlashPayModule.Getter("getStudentList")
  public paymentWorkflowsStudentList: any;

  @FlashPayModule.Getter("getStudentListTotal")
  public paymentWorkflowsStudentTotal: any;

  @FlashPayModule.Getter("getStudentListLoading")
  public paymentWorkflowsStudentListLoading: any;

  @FlashPayModule.Action("findOverviewInfo")
  public findOverviewInfo: any;

  @FlashPayModule.Getter("getOverviewInfo")
  public getOverviewInfo: any;

  @FlashPayModule.Getter("getOverviewInfoLoading")
  public getOverviewInfoIsLoading: any;

  @AppModule.Action("setSelectedFlashPayStudents")
  public setSelectedFlashPayStudents: any;

  @SaldoModule.Action("findOne")
  public findAllPaymentWorkflowBalance: any;

  public requestQuery(query: Record<any, any> | null): void {
    this.query = query;
    this.paymentWorkflowsStudentListFindAll({
      ...this.query,
      ...this.paymentFilter,
      studentName: query?.searchTerm,
      paymentWorkflowId: FLASH_PAY,
    });
  }

  protected onChangeFilter(): void {
    (this.$refs.educationTable as Table).onSearch(this.filtered);
  }

  public async mounted(): Promise<void> {
    this.cachedFilter();
    await this.findOverviewInfo(FLASH_PAY);
    await this.findAllPaymentWorkflowBalance({
      id: FLASH_PAY,
      resource: "balance/payment-workflow",
    });

    this.$root.$on("before-destroyed-component", (props: any) => {
      if (_.isBoolean(props.success) && props.success && props.component == this.name) {
        this.requestQuery(this.query);
      }
    });
  }

  protected get flashPay(): number {
    return FLASH_PAY;
  }

  protected async onPartialInvoice(): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    await this.setSelectedFlashPayStudents([...this.selectedResources]);
    if (this.selectedResources.length !== 0) {
      await this.$router.push({ name: "PartialInvoice" });
    }
  }

  protected async createFinalInvoiceConfirmed(): Promise<void> {
    await this.ok();
    if (this.generateFinalInvoicesSuccess) {
      this.requestQuery(this.query);
    }
  }

  private onFilterHandle() {
    this.setFlashPayLocalFilter({
      filter: this.paymentFilter,
      active: this.appliedFilter,
    });
    this.onFilter();
  }

  private cachedFilter() {
    if (this.flashPayLocalFilter instanceof Object) {
      const { filter, active } = this.flashPayLocalFilter;
      this.paymentFilter = filter;
      this.appliedFilter = active;
    }
  }
}
